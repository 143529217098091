export function loadStyleSheet(href: string) {
	return new Promise<void>((resolve, reject) => {
		let link = document.querySelector<HTMLLinkElement>(`link[href="${href}"]`);

		if (!link) {
			link = document.createElement('link');
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.href = href;
			link.setAttribute('data-status', 'loading');

			document.head.appendChild(link);

			const setAttributeFromEvent = (event: Event) => {
				link?.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
				if (event.type === 'load') {
					resolve();
				} else {
					reject();
				}
			};

			link.addEventListener('load', setAttributeFromEvent);
			link.addEventListener('error', setAttributeFromEvent);
		} else {
			resolve();
		}
	});
}

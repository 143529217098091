export function loadScript(src: string): Promise<void> {
	return new Promise<void>((resolve, reject) => {
		let script = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);

		if (!script) {
			script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.setAttribute('data-status', 'loading');

			document.body.appendChild(script);

			const setAttributeFromEvent = (event: Event) => {
				script?.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
				if (event.type === 'load') {
					resolve();
				} else {
					reject();
				}
			};

			script.addEventListener('load', setAttributeFromEvent);
			script.addEventListener('error', setAttributeFromEvent);
		} else {
			resolve();
		}
	});
}

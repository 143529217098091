import React, { useCallback, useEffect, useState } from 'react';
import { loadStyleSheet } from 'src/hooks/loadStylesheet';
import { loadScript } from 'src/hooks/loadScript';
import classnames from 'classnames';
import './PlayerContainer.scss';

// Split code with `React.lazy` to lazy-load your container components
const Player = React.lazy(() => import('./Player'));

const readerWarningContainer = 'reader-warning-container';
const readerWarningAlert = 'reader-warning-alert';
const readerWarningMessage = 'reader-warning-message';

// Pulling latest version of the reader from the CDN
const getReaderUrl = async (readerVersion: string | null) => {
	const latestVersionURL = `https://media.doolittle-production.wna.cloud/static/reader/latest.properties`;

	let version = readerVersion;
	if (!version) {
		const parsedResp = await fetch(latestVersionURL).then((resp) => resp.text());
		const cdnVersion = parsedResp.split('=');
		version = cdnVersion.length === 2 ? cdnVersion[1].trim() : '';
	}

	return `https://media.doolittle-production.wna.cloud/static/reader/${version}/reader.${version}`;
};

const getErrorMessage = (readerVersion: string | null) => {
	let errorMessage =
		'Error when trying to load the latest reader version. Please refresh the web page.';
	if (readerVersion) {
		errorMessage = `Error when trying to load the reader version ${readerVersion}. Please use the correct version and refresh the web page.`;
	}
	return (
		<div>
			<span className={readerWarningAlert}>Alert: </span>
			<span className={readerWarningMessage}>{errorMessage}</span>
		</div>
	);
};

function PlayerContainer() {
	const queryParams = new URLSearchParams(window.location.search);

	const [readerUrl, setReaderUrl] = useState('');
	const [readerVersion, setReaderVersion] = useState(queryParams.get('readerVersion'));
	const [isLoaded, setIsLoaded] = useState(false);
	const [lastFailedReaderVersion, setLastFailedReaderVersion] = useState<string | null>(null);

	useEffect(() => {
		getReaderUrl(readerVersion).then((url) => {
			setReaderUrl(url);
		});
	}, [readerVersion]);

	const load = useCallback(async () => {
		const cssPromise = loadStyleSheet(`${readerUrl}.css`);
		const scriptPromise = loadScript(`${readerUrl}.js`);

		try {
			await Promise.all([cssPromise, scriptPromise]);
			setIsLoaded(true);
		} catch {
			setLastFailedReaderVersion(readerVersion);
			setReaderVersion(null);
			setIsLoaded(false);
		}
	}, [readerUrl, readerVersion]);

	useEffect(() => {
		if (readerUrl) {
			load();
		}
	}, [load, readerUrl]);

	return (
		<div
			className={classnames({
				[readerWarningContainer]: lastFailedReaderVersion,
			})}
		>
			{lastFailedReaderVersion && getErrorMessage(lastFailedReaderVersion)}
			{isLoaded && <Player />}
		</div>
	);
}

export default PlayerContainer;

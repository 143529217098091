import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import PlayerContainer from './components/Player/PlayerContainer';

function App() {
	return (
		<div className="App">
			<Suspense fallback={<div>Loading...</div>}>
				<Switch>
					<Route component={PlayerContainer} exact path="/" />
					<Redirect to="/" />
				</Switch>
			</Suspense>
		</div>
	);
}

export default App;
